.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.flex {
  display: flex;
}
.w-full {
  width: 100%;
}

.justify-center {
  justify-content: center;
}
.items-center {
  align-items: center;
}
.justify-between {
  justify-content: space-between;
}

.switches {
  margin-top: 9px;
  li {
    list-style: none;
    margin-bottom: 15px;
    border-bottom: 1px solid #ced4db;
    span {
      font-size: 12px;
    }
    line-height: 14px;
    text-transform: capitalize;
    padding: 0 6px 15px 12px;

    .switch {
      display: flex;
      align-items: center;
      justify-content: space-between;
      // min-height: 20px;
    }
    .select {
      margin: 12px 0 0;
    }
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.m-r-s {
  margin-right: 1rem!important
}
.m-r-xs {
  margin-right: 0.5rem!important
}

.slick-list {
  overflow-y: visible!important;
  overflow-x: clip;
}

.ttp-helpeditor {
  .sun-editor .se-toolbar {
    position: sticky;
    top: -2rem;
    background-color: #fff !important;
    outline: none !important;
    z-index: 100 !important;
  }

  .sun-editor .se-wrapper {
    background: #f8f9fa;
    border: 1px solid #b2bcc6;
    border-radius: 4px;
  }
  .sun-editor .se-wrapper .se-placeholder {
    padding-left: 10px !important;
  }

  .sun-editor .se-btn:enabled:focus,
  .sun-editor .se-btn:enabled:hover {
    background-color: rgba(109, 127, 146, 0.21) !important;
  }

  .sun-editor .se-btn-module-border {
    background-color: #f1f2f4 !important;
  }

  .sun-editor .se-menu-list li {
    margin: 0 1px !important;
  }

  .sun-editor .se-btn {
    color: #6d7f92 !important;
    background-color: #f1f2f4 !important;
  }

  .sun-editor .se-btn:enabled.active {
    color: #4592ff !important;
  }

  .sun-editor .se-btn-list:disabled,
  .sun-editor .se-btn:disabled,
  .sun-editor button:disabled {
    background-color: #fafafa !important;
  }
}
.ratoting {
  animation: App-logo-spin infinite 2s linear;

}