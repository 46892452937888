@font-face {
  font-family: 'tamtam';
  src:  url('tamtam.eot?7nknjs');
  src:  url('tamtam.eot?7nknjs#iefix') format('embedded-opentype'),
    url('tamtam.ttf?7nknjs') format('truetype'),
    url('tamtam.woff?7nknjs') format('woff'),
    url('tamtam.svg?7nknjs#tamtam') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-ttp-"], [class*=" icon-ttp-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'tamtam' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-ttp-qr-code:before {
  content: "\e947";
}
.icon-ttp-clock:before {
  content: "\e946";
}
.icon-ttp-download:before {
  content: "\e942";
}
.icon-ttp-pencil:before {
  content: "\e944";
}
.icon-ttp-share-alt:before {
  content: "\e943";
}
.icon-ttp-printer:before {
  content: "\e945";
}
.icon-ttp-mail:before {
  content: "\e940";
}
.icon-ttp-globe:before {
  content: "\e941";
}
.icon-ttp-slider:before {
  content: "\e93e";
}
.icon-ttp-translate:before {
  content: "\e93f";
}
.icon-ttp-link:before {
  content: "\e93d";
}
.icon-ttp-hart:before {
  content: "\e93b";
  color: #d8dde2;
}
.icon-ttp-solid-comment:before {
  content: "\e93c";
  color: #d8dde2;
}
.icon-ttp-play:before {
  content: "\e939";
}
.icon-ttp-award:before {
  content: "\e93a";
}
.icon-ttp-drag:before {
  content: "\e938";
}
.icon-ttp-arrow-down:before {
  content: "\e934";
}
.icon-ttp-arrow-up:before {
  content: "\e935";
}
.icon-ttp-download-cloud:before {
  content: "\e937";
}
.icon-ttp-picture:before {
  content: "\e933";
  color: #b2bcc6;
}
.icon-ttp-double-arrow-right:before {
  content: "\e931";
  color: #6d7f92;
}
.icon-ttp-double-arrow-left:before {
  content: "\e932";
  color: #6d7f92;
}
.icon-ttp-alert-circle:before {
  content: "\e936";
  color: #b2bcc6;
}
.icon-ttp-calendar:before {
  content: "\e92f";
}
.icon-ttp-pin:before {
  content: "\e930";
}
.icon-ttp-browser:before {
  content: "\e92e";
}
.icon-ttp-arrow-back:before {
  content: "\e929";
}
.icon-ttp-star:before {
  content: "\f005";
}
.icon-ttp-star-o:before {
  content: "\f006";
}
.icon-ttp-repeat:before {
  content: "\f01e";
}
.icon-ttp-rotate-right:before {
  content: "\f01e";
}
.icon-ttp-refresh:before {
  content: "\f021";
}
.icon-ttp-question-circle-o:before {
  content: "\f29c";
}
.icon-ttp-desktop:before {
  content: "\e924";
  color: #3c4e64;
}
.icon-ttp-email-read:before {
  content: "\e927";
  color: #3c4e64;
}
.icon-ttp-man:before {
  content: "\e928";
  color: #3c4e64;
}
.icon-ttp-smartphone:before {
  content: "\e92a";
  color: #3c4e64;
}
.icon-ttp-tablet:before {
  content: "\e92b";
  color: #3c4e64;
}
.icon-ttp-woman:before {
  content: "\e92c";
  color: #3c4e64;
}
.icon-ttp-plane:before {
  content: "\e92d";
  color: #3c4e64;
}
.icon-ttp-stats:before {
  content: "\e91f";
}
.icon-ttp-article-edit:before {
  content: "\e920";
}
.icon-ttp-print:before {
  content: "\e921";
}
.icon-ttp-instagram:before {
  content: "\e922";
}
.icon-ttp-timetable-1:before {
  content: "\e923";
}
.icon-ttp-share:before {
  content: "\e925";
}
.icon-ttp-email:before {
  content: "\e926";
}
.icon-ttp-chevron-up:before {
  content: "\e91d";
}
.icon-ttp-chevron-down:before {
  content: "\e91e";
}
.icon-ttp-feather:before {
  content: "\e919";
}
.icon-ttp-article:before {
  content: "\e91a";
}
.icon-ttp-newspaper:before {
  content: "\e91b";
}
.icon-ttp-author:before {
  content: "\e91c";
}
.icon-ttp-facebook:before {
  content: "\e916";
  color: #3b5998;
}
.icon-ttp-linkedin:before {
  content: "\e917";
  color: #007ab9;
}
.icon-ttp-twitter:before {
  content: "\e918";
  color: #55acee;
}
.icon-ttp-magnifier:before {
  content: "\e903";
}
.icon-ttp-eye:before {
  content: "\e913";
}
.icon-ttp-edit:before {
  content: "\e90e";
}
.icon-ttp-megaphone:before {
  content: "\e914";
}
.icon-ttp-trash:before {
  content: "\e900";
}
.icon-ttp-paper-airplane:before {
  content: "\e915";
}
.icon-ttp-earth:before {
  content: "\e912";
}
.icon-ttp-thumb-up:before {
  content: "\e90f";
}
.icon-ttp-thumb-down:before {
  content: "\e910";
}
.icon-ttp-comment:before {
  content: "\e911";
}
.icon-ttp-medias:before {
  content: "\e90d";
}
.icon-ttp-three-dots:before {
  content: "\e90b";
}
.icon-ttp-plus:before {
  content: "\e90c";
}
.icon-ttp-close:before {
  content: "\e90a";
}
.icon-ttp-check-outline:before {
  content: "\e908";
}
.icon-ttp-close-outline:before {
  content: "\e909";
}
.icon-ttp-plus-outline:before {
  content: "\e907";
}
.icon-ttp-filter:before {
  content: "\e905";
}
.icon-ttp-tools:before {
  content: "\e902";
}
.icon-ttp-check:before {
  content: "\e901";
}
.icon-ttp-settings:before {
  content: "\e904";
}
.icon-ttp-folder:before {
  content: "\e906";
}
.icon-ttp-film:before {
  content: "\f008";
}
.icon-ttp-headphones:before {
  content: "\f025";
}
.icon-ttp-copy:before {
  content: "\f0c5";
}
.icon-ttp-files-o:before {
  content: "\f0c5";
}
.icon-ttp-bars:before {
  content: "\f0c9";
}
.icon-ttp-navicon:before {
  content: "\f0c9";
}
.icon-ttp-reorder:before {
  content: "\f0c9";
}
.icon-ttp-cloud-upload:before {
  content: "\f0ee";
}
