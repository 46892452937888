.mask {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(41, 57, 77, 0.4);
  z-index: 999;
  width: 100%;
  height: 100%;
  display: none;
  &.display {
    display: block;
  }
}

.sideBar {
  background-color: #fff;
  position: fixed;
  top: 0;
  right: 0;
  width: max-content;
  height: 100vh;
  width: 70svw;
  z-index: 1000;
  transform: translateX(100%);
  transition: 0.5s all ease-in-out;
  padding: 2rem;
  overflow-y: scroll;

  &.display {
    transform: translateX(0);
    box-shadow: 0 0 1.2rem 0 rgba(44, 43, 63, 0.3);
  }
}

.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.563rem;
}
.title {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-transform: uppercase;
}
.close {
  cursor: pointer;
  color: #29394d;
  margin-left: 2rem;
  svg {
    height: 26px;
  }
}
.leftSide {
  width: 200px;
}
.content {
  display: grid;
  grid-template-columns: 200px  1fr;
  gap: 1em;
}
