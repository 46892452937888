.add_modal{
    position: fixed;
    inset: 0px;
    z-index: 1000;
    background-color: rgba(41, 57, 77, 0.4);
    visibility: hidden;
    .modal_content{
      background-color: #F8F9FA;
      width: 70vw;
      height: 100%;
      position: absolute;
      right: 0px;
      transform: translateX(40rem);
      transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
      overflow-y: scroll;
     

    }

    &.active{
      .modal_content{
        transform: translateX(0);
      }
      visibility: visible;
    }

  }

.addButton{
  position: fixed;
  top: 50%;
  right: 0;
  z-index: 10;
  height: 3.875rem;
  width: 14rem;
  background-color: #fff;
  display: flex;
  align-items: center;
  padding-left: 20px;
  box-shadow: 0px 4px 10px rgb(41 57 77 / 30%);
  border-radius: 2rem 0 0 2rem;
  color: #29394D;
  font-weight: 600;
  transition: transform .3s ease-in-out, -webkit-transform .3s ease-in-out;
  transform: translateX(10.25rem);
  cursor: pointer;
  &:hover{
    transform: translateX(0);
    background-color: #18A0FB;
    color: #fff;
  }
}

.chainContainer {
  background-color: #f3faff;
  border: 1px solid #c7e8fe;
  border-radius: 5px;
  margin: 0 auto 40px;
  min-height: 143px;
  padding: 10px;
}
  
.wrapIcon {
  height: 120px;
  width: 100%;
}  
.icon {
  align-items: center;
  border: 1px dashed #ccc;
  color: #6d7f92;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: .75rem;
  font-weight: 500;
  height: 6.5rem;
  justify-content: center;
  text-align: center;
  width: 160px;
  height: 120px;
  margin: 0 auto 40px;
}

.icon_selected{
  font-size: .825rem;
  margin-top: 0.5rem;
  font-weight: 600;
//  color: #18A0FB;
  font-family: "Avenir Next";
  text-transform: capitalize;
  display: contents;
  i{
    font-size: 2rem;
    margin-bottom: 0.6rem;
  }
}

.lngSwitches {
  display: flex;
  justify-content: end;
  span {
    font-size: 12px;
    margin: 10px;
    text-transform: uppercase;
  }
  .switch {
    display: flex;
    align-items: center;
  }
  

}

.leftSide {
  p {
    color: #3c4e64;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    margin-bottom: 10px;
    text-transform: uppercase;
  }
}

.actions  {
  display: flex;
  justify-content: space-between;
  .save_butt{
    margin-right: 10px;
    height: 2.5rem;
    width: 8rem;
    background-color: #18A0FB;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.3rem;
    color: white;
    cursor: pointer;

    &.disable{
      background-color: #18a0fb5c;
      cursor: not-allowed;
    }
  }

  .cancel_butt{
    height: 2.5rem;
    width: 7rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.3rem;
    border: 1px solid rgba(109, 127, 146, 0.5);
    color: #6d7f92;
    cursor: pointer;
  }
}

.singleSwitch {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding-bottom: 10px;
  border-bottom: 1px solid #ced4db;
}


// css help pro

.formRow {
  margin-bottom: 1.5rem;
}

.formInput {
  display: block;
  width: 100%;
  padding: 2px 10px;
  height: 38px !important;
  border: 1px solid #ced4db;
  border-radius: 5px;
  color: #29394d;
  background-color: #f8f9fa;
  border: 1px solid #b2bcc6;
}

.formTextarea {
  width: 100%;
  height: 150px;
  padding: 2px 10px;
  border: 1px solid #ced4db;
  border-radius: 5px;
  color: #29394d;
  background-color: #f8f9fa;
  border: 1px solid #b2bcc6;
  resize: none;
}

.configRow {
  display: flex;
  width: 100%;
  margin-bottom: 1.5rem;
}

.configColumn {
  width: 50%;
  padding-right: 20px;

  input {
    // height: 1rem;
    box-shadow: none;
    margin: 0;
  }
}


.configColumn {
  width: 50%;
  padding-right: 20px;

  input {
    // height: 1rem;
    box-shadow: none;
    margin: 0;
  }
}

.configLabel {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: #3c4e64;
  display: block;
  margin-bottom: 10px;

  &_mar {
    margin-bottom: 20px;
  }
}


