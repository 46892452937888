$white: #fff;
// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold: 150 !default;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark: #212529;
$yiq-text-light: $white !default;

@import "../../../styles/base/variables";
@import "../../../styles/base/functions";
@import "../../../styles/base/mixins/gradients";

// Buttons Variables
//

$font-size-base: 1rem !default;
$font-size-lg: ($font-size-base * 1.25) !default;
$font-size-sm: ($font-size-base * 0.875) !default;

$btn-padding-y: 0.5rem;
$btn-padding-x: 1.5rem;
// $btn-padding-y: .375rem;
// $btn-padding-x: .75rem;
$btn-line-height: 1.5 !default;

$btn-padding-y-sm: 0.25rem;
$btn-padding-x-sm: 0.5rem;
$btn-line-height-sm: 1.5 !default;

$btn-padding-y-lg: 1rem;
$btn-padding-x-lg: 2rem;
$btn-line-height-lg: 1.5 !default;

$btn-border-width: 1px !default;

$btn-font-weight: 700 !default;
$btn-box-shadow: 0 1px 0 rgba($white, 0.15), 0 1px 1px rgba(#000, 0.075);
$btn-focus-width: 0.2rem;
$btn-focus-color: rgba(#18a0fb, 0.25);
$btn-focus-box-shadow: 0 0 0 $btn-focus-width $btn-focus-color;
$btn-disabled-opacity: 0.65;
$btn-active-box-shadow: inset 0 3px 5px rgba(#000, 0.125);

$btn-link-disabled-color: #6c757d;

$btn-block-spacing-y: 0.5rem;

$btn-border-radius: 5px;
$btn-border-radius-lg: $btn-border-radius;
$btn-border-radius-sm: $btn-border-radius;

$btn-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !default;

//
// Base mixins
//

// Button variants
//
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons

@mixin button-variant(
    $background,
    $border,
    $hover-background: darken($background, 7.5%),
    $hover-border: darken($border, 10%),
    $active-background: darken($background, 10%),
    $active-border: darken($border, 12.5%)
) {
    color: color-yiq($background);
    @include gradient-bg($background);
    border-color: $border;
    box-shadow: $btn-box-shadow;

    &:hover {
        color: color-yiq($hover-background);
        @include gradient-bg($hover-background);
        border-color: $hover-border;
    }

    &:focus,
    &.focus {
        box-shadow: $btn-box-shadow, 0 0 0 $btn-focus-width rgba($border, 0.5);
    }

    // Disabled comes first so active can properly restyle
    &.disabled,
    &:disabled {
        color: color-yiq($background);
        background-color: $background;
        border-color: $border;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
        color: color-yiq($active-background);
        background-color: $active-background;
        background-image: none;
        border-color: $active-border;

        &:focus {
            box-shadow: $btn-active-box-shadow,
                0 0 0 $btn-focus-width rgba($border, 0.5);
        }
    }
}

// Button sizes
@mixin button-size(
    $padding-y,
    $padding-x,
    $font-size,
    $line-height,
    $border-radius
) {
    padding: $padding-y $padding-x;
    font-size: $font-size;
    line-height: $line-height;
    border-radius: $border-radius !important; //TODO remove important
}

//
// Base styles
//

.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    text-align: center;
    user-select: none;
    border: $btn-border-width solid transparent;
    @include button-size(
        $btn-padding-y,
        $btn-padding-x,
        $font-size-base,
        $btn-line-height,
        $btn-border-radius
    );
    transition: $btn-transition;

    // Share hover and focus styles
    &:hover,
    &:focus,
    &.focus {
        text-decoration: none;
    }

    &:focus,
    &.focus {
        outline: 0;
        box-shadow: $btn-focus-box-shadow;
    }

    // Disabled comes first so active can properly restyle
    &.disabled,
    &:disabled {
        opacity: $btn-disabled-opacity;
        box-shadow: none;
    }

    // Opinionated: add "hand" cursor to non-disabled .btn elements
    &:not(:disabled):not(.disabled) {
        cursor: pointer;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
        box-shadow: $btn-active-box-shadow;

        &:focus {
            box-shadow: $btn-focus-box-shadow, $btn-active-box-shadow;
        }
    }
}

//
// Alternate buttons
//

@each $color, $value in $theme-colors {
    .btn-#{$color} {
        @include button-variant($value, $value);
    }
}

.btn-default {
    @include button-variant(#fff, #b2bcc6);
}
.btn-outline {
    background-color: transparent !important;
    border: 1px solid rgba(109, 127, 146, 0.5);
    color: #6d7f92;
}

//
// Button Sizes
//

.btn-lg {
    @include button-size(
        $btn-padding-y-lg,
        $btn-padding-x-lg,
        $font-size-lg,
        $btn-line-height-lg,
        $btn-border-radius-lg
    );
}

.btn-sm {
    @include button-size(
        $btn-padding-y-sm,
        $btn-padding-x-sm,
        $font-size-sm,
        $btn-line-height-sm,
        $btn-border-radius-sm
    );
}

//
// Block button
//

.btn-block {
    display: block;
    width: 100%;

    // Vertically space out multiple block buttons
    + .btn-block {
        margin-top: $btn-block-spacing-y;
    }
}

.btn-icon {
    margin-right: map-get($spacers, 4);
}
