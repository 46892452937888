.switches {
    margin-top: 9px;
    li {
      list-style: none;
      margin-bottom: 15px;
      border-bottom: 1px solid #ced4db;
      span {
        font-size: 12px;
      }
      line-height: 14px;
      text-transform: capitalize;
      padding: 0 6px 15px 12px;
  
      .switch {
        display: flex;
        align-items: center;
        justify-content: space-between;
        // min-height: 20px;
      }
      .select {
        margin-top: 6px;
      }
    }
}
  