.close {
    margin: 30px 30px 0 auto;
    cursor: pointer;
    color: #29394d;
    width: 17px;
    svg {
      height: 26px;
    }
  }
.add_modal_icons{
    position: fixed;
    inset: 0px;
    z-index: 1000;
    background-color: rgba(41, 57, 77, 0.4);
    visibility: hidden;

    .modal_icons_content{
      background-color: #F8F9FA;
      width: 70svw;
      height: 100%;
      position: absolute;
      right: 0px;
      transform: translateX(40rem);
      transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
      overflow-y: scroll;
      li{
        i{
          font-size: 1.75rem;
        }
      }

      .filter_icons_container{
        width: 100%;
        padding-right: 2.4rem;
        padding-left: 2.6rem;
        .filter_icons{
          display: flex;
          align-items: center;
          background-color: #fff;
          padding: 0 0 0 1.25rem;
          height: 2rem;
          border-radius: 2rem;
          border: 1px solid #F1F2F4;

          input{
            margin: 0;
            width: 100%;
            height: 100%;
            border: none;
            box-shadow: none;
            font-size: .75rem;
            margin-left: 4px;
            border-radius: 2rem;
          }
        }
      }

    }

    &.active{
      .modal_icons_content{
        transform: translateX(0);
      }
      visibility: visible;
    }
    .icons_item{
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        height: 5.5rem;
        width: 6.5rem;
        margin-right: 25px;
        min-height: inherit;
        font-size: .75rem;
        font-weight: 500;
        text-align: center;
        padding: 0 0.5rem;
        cursor: pointer;
        box-shadow: 0px 0px 4px rgb(41 57 77 / 10%);
        margin-bottom: 1rem;
        border-radius: 5px;
        overflow: hidden;
        transition: all .1s ease-in-out;
        &:hover{
          color: #18A0FB;
          transform: scale(1.1);
          background-color: #F3FAFF;
          border: 1px solid #18A0FB;
        }
      }
  }