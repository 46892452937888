.tab-list {
  color: #8d8d8d;
  border-bottom: 3px solid #b2bcc6;
  margin: 0;
  padding: 0;
}
.tab-content {
  border-top: none;
  box-sizing: border-box;
  padding: 28px 0;
}
.tab-hidden {
  display: none !important;
}

.tab-list-item {
  display: inline-block;
  list-style: none;
  cursor: pointer;
  margin-bottom: -4px;
  padding: 0.5rem 0.75rem;
  font-size: 14px;
  line-height: 16px;
  text-transform: capitalize;
  color: #6d7f92;

  > div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 35px;
  }
  img {
    margin-right: 7px;
    height: 11px;
  }
}

.tab-list-active {
  position: relative;
  &:after {
    content: "";
    width: 100%;
    height: 5px;
    background-color: #18a0fb;
    position: absolute;
    left: 0;
    bottom: 0;
    border-radius: 10px;
  }
}
