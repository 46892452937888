@import "../../styles/base/variables";

.my_space{
  display: block;
  padding: 3rem;

  .header{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 38px;

    text-transform: uppercase;

    color: #29394D;
  }

  .filter {
    .search_frame {
      justify-content: end;
      .search {
        display: flex;
        width: 24%;
        align-items: center;
        background-color: #fff;
        padding: 0 0 0 1.25rem;
        height: 2rem;
        border-radius: 2rem;
        border: 1px solid $new-light-gray;
        i {
          font-size: 1rem;
          color: $new-gray;
        }
        input {
          margin: 0;
          height: 100%;
          border: none;
          box-shadow: none;
          font-size: .75rem;
          margin-left: 1rem;
          border-radius: 2rem;
          width: 99%;
          &:focus {
            border: none;
            box-shadow: none;
          }
        }
      }
      .lng_item {
        height: 32px;
        width: 32px;
        margin: 0px 4px;
        border-radius: 50%;
        background: #F1F2F4;
        border: 1px solid #D8DDE2;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        text-transform: uppercase;
        color: #6D7F92;

        &.activated {
          color: #F1F2F4;
          background: #18A0FB;
        }
      }
    }
    .tags{
      margin-top: 2rem;
      .tag_item {
        border: 1px solid;
        padding: 9px 18px;
        margin: 4px;
        height: 32px;
        background: #F1F2F4;
        border-radius: 30px;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        text-transform: uppercase;
        color: #6D7F92;
        cursor: pointer;
        &.activated {
          background-color: #6D7F92;
          color: #fff;
          font-weight: 600;
        }
      }
    }
  }
}
.grid_card {
  padding: 10;
}

