@import "../../../styles/base/variables";
@import "../../../styles/base/functions";
@import "../../../styles/base/mixins/gradients";

.not_found_v2 {
  background: transparent;
  margin: 5rem auto 1rem;
  color: #2C394B;
  font-size: 1.5rem;
  &_main {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
      font-size: 2.5rem;
      line-height: 1.4;
      margin-top: 0;
      margin-bottom: 0.5rem;
    }
    h5 {
      font-size: 1.25rem;
      line-height: 1.4;
      margin-top: 0;
      margin-bottom: 0.5rem;
    }
    p {
      font-size: 16px;
      line-height: 18px;
      color: #3F4E62;
      margin-bottom: 1rem;
    }

    .goBack {
      background: #FFFFFF;
      letter-spacing: 0.06em;
      color: #18A0FB;
      min-width: 180px;
      padding: 0 1rem;
      height: 44px;
      border: 1px solid rgba(199, 232, 254, 0.5);
      box-sizing: border-box;
      box-shadow: 0px 0px 10px rgb(41 57 77 / 10%);
      border-radius: 30px;
      font-family: Roboto;
      font-weight: 500;
      text-align: center;
      font-size: 16px;
      display: -ms-flexbox;
      display: flex;
      justify-content: center;
      align-items: center;
      outline: none;
      margin-left: 22px;
      cursor: pointer;
      text-transform: uppercase;
    }

    :global(.btn) {
      //min-width: 300px;
      a {
        text-transform: initial;
        letter-spacing: 1px;
        color: #18A0FB;
        padding-right: 10px;
      }
    }
  }
  &__text {
    display: flex;
    align-items: center;
  }
}
.not_found_v2_iframe{
  width: 750px;
}
