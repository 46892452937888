.status {
    display: flex;
    align-items: center;
    margin: 2rem 0;
}
.status_item {
    padding: 10px;
    color: #6d7f92;
    background: #f1f2f4;
    border: 0.5px solid #f1f2f4;
    box-sizing: border-box;
    border-radius: 30px;
    text-align: center;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    margin: 0 4px;

    &:hover,
    &.selected {
        cursor: pointer;
        color: #fff;
        background: #6d7f92;
    }
}

.noData {
    text-align: center;
    color: #2c394b;
    font-size: 2rem;
    margin: 6rem 0;

    div {
        margin: 2rem 0;
    }
}

.item {
    margin-bottom: 10px;
    background-color: #fafbfb;
    border-radius: 5px;

    &_top {
        display: flex;
        align-items: center;
        height: 80px;
    }

    &_head {
        font-weight: bold;
        text-align: center;
        background-color: transparent;

        &:hover {
            background-color: transparent !important;
        }
    }

    &:hover {
        background-color: #f1f2f4;
    }

    &_date {
        width: 110px;
        text-align: center;
    }

    &_title {
        width: 55%;
    }

    &_type {
        width: 100px;
        text-align: center;
    }

    &_status {
        width: 100px;
        text-align: center;
    }

    &_actions {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 2px;

        .action {
            font-size: 12px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            background: #fff;
            border: 0.5px solid #c7e8fe;
            box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
            border-radius: 50px;
            &:hover {
                color: #fff;
                background: #c7e8fe;
            }
        }
    }
}

.content {
    display: none;
    padding: 1rem;
    background-color: #fff;
    transition: all 300ms cubic-bezier(0.17, 0.04, 0.03, 0.94);

    &_title {
        font-size: 1rem;
        line-height: 18px;
        margin-bottom: 1rem;
    }
}

.item.active {
    .content {
        display: block;
    }
}

.pending {
    color: #ffb340;
}

.accepted {
    color: #06d9b1;
}

.rejected {
    color: #eb5757;
}
