@import "../../styles/base/variables";

.mb-4 {
  margin-bottom: 40px !important;
}
.ripple_loading {
  text-align: center;
  padding-top: 14rem;
  margin: 0px 650px;
}

.header {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 140%;
  text-align: center;
  color: #29394D;
  margin-bottom: 34px;

  span {
    text-transform: uppercase;
    color: $dodger-blue;
  }
}

.filter {
  .search_frame {
    
    .search {
      display: flex;
      margin-right: 14px;
      align-items: center;
      background-color: #fff;
      padding: 0 0 0 1.25rem;
      height: 2rem;
      border-radius: 2rem;
      border: 1px solid $new-light-gray;
      i {
        font-size: 1rem;
        color: $new-gray;
      }
      input {
        margin: 0;
        height: 100%;
        border: none;
        box-shadow: none;
        font-size: .75rem;
        margin-left: 1rem;
        border-radius: 2rem;
        width: 99%;
        &:focus {
          border: none;
          box-shadow: none;
        }
      }
      margin-bottom: 19px;
    }
    .lng_item {
      height: 32px;
      width: 32px;
      margin: 0px 4px;
      border-radius: 50%;
      background: #F1F2F4;
      border: 1px solid #D8DDE2;
      display: flex;
      align-items: center;
      justify-content: center;
  
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      text-transform: uppercase;
      color: #6D7F92;
      margin-bottom: 19px;

      &.activated {
        color: #F1F2F4;
        background: #18A0FB;
      }
    }
  }
}

.tag_item {
  padding: 9px 18px;
  margin: 0px 4px 10px;
  height: 32px;
  background: #F1F2F4;
  border-radius: 30px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  text-transform: uppercase;
  color: #6D7F92;
  &.activated {
    background-color: #6D7F92;
    color: #fff;
    font-weight: 600;
  }
}

.search_frame {
  &_title {
    display: flex;
    align-items: center;
    margin: 10px 70px 0px;
    h3 {
      margin-right: 10px;
    }
    .action {
      display: flex;
      justify-content: space-around;
      height: 26px;
      align-items: center;
      background: rgba(109, 127, 146, 0.1);
      border: 0.5px solid #F1F2F4;
      border-radius: 5px;
      padding: 0px 7px;
      cursor: pointer;
      
      span {
        margin-right: 7px;
        padding-bottom: 2.5px;
        text-transform: lowercase;
      }
    }
  }
  
}

.category_items {
  // max-width: 91.66667%;
  // margin: 14px auto 0px;

  .category_item {
    display: flex;
    padding: 14px;
    background: #FFFFFF;
    border: 1px solid hsl(220, 12%, 95%);
    border-radius: 5px;
    &_icon {
      width: 52px;
      height: 52px;
      display: flex;
      font-size: 26px;
      border-radius: 50%;
      margin-right: 17px;
      align-items: center;
      justify-content: center;
      background: rgba(254, 179, 64, 0.2);
    }
    &_infos {
      font-family: 'Roboto';
      font-style: normal;
      padding: 5px 0px;

      .category_item_title {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #29394D;
        margin-bottom: 7px;
      }
      .category_item_count {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #6D7F92;
      }
    }
  }
}

hr {
  max-width: none!important;
  border: none!important;
  // width: 1280px!important;
  height: 1px !important;
  background: #D8DDE2;
  margin: 30px 10px !important;
}

.aside_frame_title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  text-transform: uppercase;
  color: #29394D;

  span {
  color: #18A0FB;
    font-size: 20px;
    line-height: 23px;
  }
}

.aside_frame_btn {
  opacity: 0.6;
  width: 234px;
  height: 44px;
  cursor: pointer;
  background: #FFFFFF;
  border: 1px solid #D8DDE2;
  border-radius: 5px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;
  color: #29394D;
  filter: drop-shadow(0px 4px 10px rgba(41, 57, 77, 0.1));
}


.grid_card {
  padding: 10px;
}

.recommanded_frame {
  // max-width: 91.66667%;
  margin: 0;
  &_fiche {
    width: 100%;
    background: #F1F2F4;
    border-radius: 5px;
    padding: 25px 21px;

    .frame-logo {
      display: inline-block;
      background: #fff;
      padding: 5px;
      margin: 10px 0;
      border-radius: 5px;
      img {
        max-height: 80px;
      }
    }
    .recommanded_frame_img {
      background-image: url("../../../public/img/m-logo.png");
      width: 100px;
      height: 60px;
      margin: 16px 0px;
    }

    p {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      text-transform: capitalize;
      color: #6D7F92;
      margin: 16px 0px;
    }
  }
  .grid_card_footer {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    .justify-center {
      justify-content: center !important;
    }
    .recommanded_footer_btn {
      width: 240px;
      height: 44px;
      display: flex;
      cursor: pointer;
      margin: 0px 20px;
      padding: 0px 25px;
      border-radius: 50px;
      align-items: center;
      background: #FFFFFF;
      border: 1px solid #D8DDE2;
      justify-content: space-between;
      box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
      
      span {
        margin: 0px 10px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: #29394D;
        text-transform: uppercase;
      }

      &.disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
  }
}

.galery_frame {
  // max-width: 91.66667%;
  // margin: 34px auto 0px;

  .aside_frame {
    background: #F1F2F4;
    border-radius: 5px;
    padding: 0px 0px 20px;
    min-height: 100%;

    &_title {
      padding: 21px;
    }

    &_rank {
      position: absolute;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 600;
      font-size: 80px;
      line-height: 94px;
      color: #D8DDE2;
      right: 85px;
    }
    > hr {
      width: 281px!important;
      background: #D8DDE2;
      margin: 15px auto!important;
    }
  }
}

.gradiant {
  background-color: rgb(238, 238, 238);
  background-image: linear-gradient(
    90deg,
    rgb(238, 238, 238),
    rgb(245, 245, 245),
    rgb(238, 238, 238)
  );
  background-repeat: no-repeat;
  animation: 1.2s ease-in-out 0s infinite normal none running;
  animation-name: loading;
}

.card_tool_fetching {
  height: 192px;
  margin: 5px 9px;
  width: 305px;
}

.card_fetching {
  height: 102px;
  margin: 5px 9px;
  width: 305px;
}


.created_frame {
  // max-width: 91.66667%;
  // margin: 60px auto 0px;
  margin-top: 50px;
  

  .aside_frame {
    // width: 305px;
    height: 292px;
    background: #29394D;
    border-radius: 5px;
    padding: 21px;

    color: #C7E8FE;
    &_title {
      color: #C7E8FE;
      span {
        color: #18A0FB;
        font-size: 20px;
        line-height: 33px;
      }
    }
    p {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      text-transform: capitalize;
      color: #D8DDE2;
      margin: 16px 0px 26px;
    }
    .aside_frame_btn {
      opacity: 1;
      background: #C7E8FE;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
  }
}

.addButton{
  position: fixed;
  top: 50%;
  right: 0;
  z-index: 10;
  height: 3.875rem;
  width: 14rem;
  background-color: #fff;
  display: flex;
  align-items: center;
  padding-left: 20px;
  box-shadow: 0px 4px 10px rgb(41 57 77 / 30%);
  border-radius: 2rem 0 0 2rem;
  color: #29394D;
  font-weight: 600;
  transition: transform .3s ease-in-out, -webkit-transform .3s ease-in-out;
  transform: translateX(10.25rem);
  cursor: pointer;
  &:hover{
    transform: translateX(0);
    background-color: #18A0FB;
    color: #fff;
    a {
      color: #fff;
    }
  }
  a {
    color: #29394d;
    display: flex;
    align-items: center;
    a:hover {
      color: #fff;
    }
  }
  .add-text {
    margin-left: 1rem;
    text-align: center;
    width: 100%;
    text-transform: uppercase;
  }
}
