$shadow-like-border: 0 0 0 0.06rem rgba(0, 0, 0, 0.06), 0 0.13rem 0.2rem 0 rgba(0, 0, 0, 0.06);
$main-blue: rgb(24, 160, 251);
$h-font-family: "Avenir Next";

.switches {
  // padding: .9rem 0 1.4rem 0;
  font-family: $h-font-family;
  font-size: 0.8rem;
}
.item {
  height: 44px;
  background: #ffffff;
  border-radius: 4px 4px 0px 0px;

  font-style: normal;
  font-weight: normal;
  span {
    font-size: 12px;
  }
  line-height: 16px;
  color: #29394d;

  display: flex;
  align-items: center;
  padding-left: 24px;
  padding-right: 6px;
  margin-bottom: 6px;
  border-bottom: 1px solid #ced4db;
  &:not(:last-child) {
    margin-bottom: 6px;
  }
}

.itemLabel {
  margin-right: auto;
}

.switch {
  position: absolute;
  opacity: 0;

  & + div {
    vertical-align: middle;
    transition-duration: 0.4s;
    transition-property: background-color, box-shadow;
    cursor: pointer;
    width: 40px;
    height: 16px;
    background-color: #dbe0e5;
    border-radius: 20px;

    & > div {
      position: relative;
      top: -25%;
      left: 0;
      width: 24px;
      height: 24px;
      background: #b6bfc8;
      border: 1px solid #ffffff;
      border-radius: 50%;
      box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);

      transition-timing-function: cubic-bezier(1, 0, 0, 1);
      transition-duration: 0.4s;
      transition-property: transform, background-color;
      pointer-events: none;
    }
    span {
      position: absolute;
      font-size: 1.6rem;
      color: white;
      margin-top: 12px;
      &:nth-child(1) {
        margin-left: 15px;
      }
      :nth-child(2) {
        margin-left: 55px;
      }
    }
  }
}
.checked {
  & + div {
    background-color: #c7e8fe;

    & > div {
      background-color: #18a0fb;
      transform: translate3d(16px, 0, 0);
    }
  }
}
