.modalOverlay {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 999;
    background-color: rgba(41, 57, 77, 0.4);
}

.modalContent {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    border: none;
    background: transparent;
    border-radius: 4px;
    outline: none;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.modalContentAfterOpen {
    .modal {
        top: 0;
        opacity: 1;
    }
}
.modalContentBeforeClose {
    .modal {
        top: 50%;
        opacity: 0;
    }
}
.modal {
    position: relative;
    top: 50%;
    width: 75%;

    opacity: 0;
    box-shadow: 0px 0px 10px rgba(41, 57, 77, 0.25);
    border-radius: 5px;
    transition: all 200ms ease-in-out;

    color: #2495e1;
    background: #f3faff;
    border-bottom: 1px solid #c7e8fe;
}

.modal_close {
    position: absolute;
    top: -18px;
    right: -18px;
    height: 36px;
    width: 36px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    color: #3c4e64;
    background: #ffffff;
    border: 1px solid #f1f2f4;
    border-radius: 50%;
}

.modal_header {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;
    padding: 0 1rem;
    background: #f3faff;
    border-bottom: 1px solid rgba(199, 232, 254, 0.5);
    border-radius: 0px 0px 5px 5px;
    font-size: 20px;
    line-height: 23px;
    color: #2495e1;

    &_sub {
        font-size: 18px;
        margin-left: 10px;
    }
}
.modal_icon {
    margin-right: 1rem;
}
.modal_body {
    width: 100%;
    height: 100%;
    min-height: 50vh;
    max-height: 39rem;
    overflow-y: scroll;
    padding: 42px;
    border-bottom: 1px solid #f1f2f4;
    background-color: #f8f9fa;
    font-size: 18px;
    line-height: 21px;
    color: #29394d;

    input {
        height: 1rem;
        box-shadow: none;
        margin: 0;
    }
}
.configLabel {
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    color: #3c4e64;
}

.action {
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    cursor: pointer;
    width: 40px;
    height: 40px;
    border: 0;
    border-radius: 40px;
    margin: 6px;
    color: #fff;
    background: #1890ff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    &_big {
        width: 44px;
        height: 44px;
        margin: 0 0 0 10px;
    }

    &:hover {
        background: #40a9ff;
    }
}
.container {
    display: flex;
    align-items: baseline;
}

.flex_left {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 72px;
}

.flex_right {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 72px;
}

.select {
    width: 30%;
    margin-left: 5px;
}

.actions {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
}

.formRow {
    margin-bottom: 2rem;
}

.formLabel {
    margin-bottom: 1rem;
    display: block;
}

.controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid rgba(109, 127, 146, 0.1);
    border-radius: 2px;
    background-color: #ffffff;
    padding: 10px;
    width: 100%;
}
