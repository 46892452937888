@import "../../styles/base/variables";

.ripple_loading {
  text-align: center;
  padding-top: 14rem;
  margin: 0px 650px;
}
.no-data-found {
  margin: 20px auto;
  text-align: center;
  min-width: 316px!important;
}

.page_header {
  background: #f3faff;
  color: #3c4e64;
  border-radius: 0 0 10px 10px;
  margin-bottom: 23px;
  width: 100%;
  display: flex;
  height: 96px;
  padding: 18px 0.625rem;
  color: #3c4e64;
  font-family: Roboto;

  &_info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;
    padding-left: 30px;
    border-left: 1px solid #fff;
  }

  &_icon {
    font-size: 40px;
    padding: 10px;
  }
  & > div {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    min-width: 75rem;
    
    h3 {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 28px;
      color: #29394D;
      text-transform: uppercase;
    }
    p {
      font-size: 14px;
    }
  }
}

.library_frame {
  padding: 1rem;
  background: #fff;
  border: 1px solid #f1f2f4;
  box-sizing: border-box;
  border-radius: 5px;

  &_filter {
    border-bottom: 1px solid #f1f2f4;
    margin: 0px 7px 10px;
    .search_frame {
      margin-bottom: 7px;
      .search {
        display: flex;
        align-items: center;
        background-color: #fff;
        padding: 0 0 0 1.25rem;
        border-radius: 2rem;
        border: 1px solid $new-light-gray;
        i {
          font-size: 1rem;
          color: $new-gray;
        }
        input {
          margin: 0;
          height: 100%;
          border: none;
          box-shadow: none;
          font-size: .75rem;
          margin-left: 1rem;
          border-radius: 2rem;
          width: 99%;
          &:focus {
            border: none;
            box-shadow: none;
          }
        }
      }
      .lng_item {
        margin: 0px 4px;
        background: #F1F2F4;
        border: 1px solid #D8DDE2;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        padding: 10px;
        margin: 0 2px;
        color: #6d7f92;
        background: #f1f2f4;
        border: 0.5px solid #f1f2f4;
        box-sizing: border-box;
        border-radius: 30px;

        font-size: 12px;
        line-height: 14px;
        text-align: center;
        letter-spacing: .02em;
        text-transform: uppercase;
  
        &.activated {
          color: #fff;
          background: #6d7f92;
        }
      }
    }
    .tags_frame {
      margin-right: auto;
      .tag_item {
        margin: 0px 4px;
        background: #F1F2F4;
        border: 1px solid #D8DDE2;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin: 0 2px;
        color: #6d7f92;
        background: #f1f2f4;
        font-weight: 400;
        border: 0.5px solid #f1f2f4;
        box-sizing: border-box;
        border-radius: 30px;
        padding: 10px 16px;

        font-size: 12px;
        line-height: 14px;
        text-align: center;
        letter-spacing: .02em;
        text-transform: uppercase;
  
        &.activated {
          color: #fff;
          background: #6d7f92;
        }
      }
    }
    .minimise_frame {
      padding: 5px 2px 5px 5px;
    }
  }
}

.private_frame {
  padding: 0.5rem;
  background: #fff;
  border: 1px solid #f1f2f4;
  box-sizing: border-box;
  border-radius: 5px;
  margin-left: 1.25rem;
  
  .library_frame_filter {
    border-bottom: none;
    // .search {
      // width: 178px!important
    // }
  }
  .tags_scopes {
    display: flex;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #6d7f92;
    align-items: center;
    margin: 5px;
    border-bottom: 1px solid #e5e6e6;
    padding: 5px 0px;
    > div {
      display: flex;
      width: 200px;
      overflow: scroll;
      .tags_scope {
        display: flex;
        align-items: center;
        justify-content: space-around;
        height: 22px;
        color: #29394d;
        background: #f1f1f1;
        border-radius: 5px 3px 5px 5px;
        padding: 2px 5px;
        font-size: 12px;
        margin: 0 2px;
      }
    }
    label {
      margin-right: 5px;
    }
    
  }
}

.page_content {
  max-width: 91.66667%;
  margin: 34px auto 0px;
  h2 {
    font-weight: 500;
    font-size: 18px;
    line-height: 126%;
    text-transform: uppercase;
    color: #29394d;
    margin-bottom: 1.5rem;
    padding: 8px;
  }
}

.grid_card {
  padding: 10px;
}

.gradiant {
  background-color: rgb(238, 238, 238);
  background-image: linear-gradient(
    90deg,
    rgb(238, 238, 238),
    rgb(245, 245, 245),
    rgb(238, 238, 238)
  );
  background-repeat: no-repeat;
  animation: 1.2s ease-in-out 0s infinite normal none running;
  animation-name: loading;
}

.card_tool_fetching {
  height: 192px;
  width: 300px;
}

.card_fetching {
  height: 102px;
  margin: 5px 9px;
  width: 305px;
}

.customed_select {
  background: #F8F9FA;
  min-height: 32px;
  border-radius: 20px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #3c4e64;
  width: 305px;
  cursor: pointer;
  margin: 0;

  &_header {
    display: flex;
    align-items: center;
    padding: 3px 0px;
    min-height: 32px;
    border-radius: 20px;
    background-origin: content-box;
    background-position: right -1.4rem center;
    background-repeat: no-repeat;
    background: #E8EAED url("../../../public/img/icons/polygon_down.svg") no-repeat 95% 50%;
    background-size: 16px 7px;
    padding-right: 1.6rem;
    padding-left: 1.1rem;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
    border: none;
    

    &_up {
      background-image: url("../../../public/img/icons/polygon_up.svg"); 
    }
    &_down {
      background-image: url("../../../public/img/icons/polygon_down.svg");
    }

    > svg {
      margin-right: 5px;
    }

    label {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 240%;
      text-transform: uppercase;
      color: #6D7F92;
      margin-left: 13px;
    }
    .select_tags {
      display: flex;
      flex-wrap: wrap;
      max-width: 230px;
      .select_tag {
        display:flex;
        margin: 1px 2px;
        padding: 0px 15px;
        align-items: center;
        height: 24px;
        background: #6D7F92;
        box-shadow: 0px 4px 10px rgb(41 57 77 / 10%);
        border-radius: 16px;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 13px;
        color: #FFFFFF;
  
        svg {
          margin-left: 15px;
        }
      }
    }
    
  }

  &_body{
    padding: 0px 10px;

    &_items {
      overflow: auto;
      max-height: 280px;
      margin-top: 10px;
    }

    &_item {
      width: 330px;
      height: 34px;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 5px;
      padding: 0px 7px 7px;
      box-sizing: border-box;
      border-bottom: 1px solid rgba(109, 127, 146, 0.1);

      &_label {
      padding-top: 7px;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 14px;
      }
    }
  }

  &_opened {
    height: fit-content;
    box-shadow: 0px 2px 3px rgba(41, 57, 77, 0.1);
  }
}

.wrap-card {
 position: relative;
 .share-action {
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #fff;
  border: 0.5px solid #fec9c7;
  box-shadow: 0 4px 10px rgba(41, 57, 77, 0.1);
  cursor: pointer;
 }
 .lib-action {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  > div {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color:rgba(36,149,225, 0.8);
    cursor: pointer;
    margin: 4px;
    color: #fff;
    font-size: 10px;
    &:hover {
      background-color:rgba(36,149,225, 1);
    }
  }
 }
 &:hover .lib-action {
  z-index: 9;
 }

 &:hover .share-action {
  z-index: 9;
  color: red;
  font-size: 10px;
 }
 &.add .share-action {
  border-color: #c7fed3;
  color: rgb(110, 219, 110);
}
}
