/* reset css  */
/* Box sizing rules */
*,
*::before,
*::after {
    box-sizing: border-box;
}
*:focus {
    outline: none;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.page-content {
    background: #F4F7F9;
    padding: 20px;
    min-height: calc(100vh - 110px);
}

.align-center {
    justify-content: center;
}

body {
    color: $new-title-color;
    font-family: $font-family-base;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    background: $body-bg;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
