@import "../../styles/base/variables";

.management_tabs {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  .tab {
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 20px rgb(41 57 77 / 20%);
    border-radius: 5px;
    margin: 0 10px;
    cursor: pointer;
    position: relative;
    width: 206px;
    height: 52px;
    background: #f4f7f9;
    span {
      font-family: Roboto, "Segoe UI", Helvetica, sans-serif;
      font-style: normal;
      font-size: 16px;
      text-align: center;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      color: #18a0fb;
    }
    &.active {
      background: linear-gradient(180deg, #2495e1 0%, #18a0fb 100%);

      span {
        color: white;
      }
    }
    .tag {
      position: absolute;
      width: 22px;
      height: 22px;
      font-size: 10px;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #f7953e;
      border: 2px solid #fff;
      color: #fff;
      border-radius: 50%;
      top: -8px;
      right: -5px;

    }
  }
}

.content {
  padding: 20px;

  .addButton {
    position: fixed;
    top: 50%;
    right: 0;
    z-index: 10;
    height: 3.875rem;
    width: 14rem;
    background-color: #fff;
    display: flex;
    align-items: center;
    padding-left: 20px;
    box-shadow: 0px 4px 10px rgb(41 57 77 / 30%);
    border-radius: 2rem 0 0 2rem;
    color: #29394d;
    font-weight: 600;
    transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    transform: translateX(10.25rem);
    cursor: pointer;
    &:hover {
      transform: translateX(0);
      background-color: #18a0fb;
      color: #fff;
    }
  }

  .add_modal {
    position: fixed;
    inset: 0px;
    z-index: 1000;
    background-color: rgba(41, 57, 77, 0.4);
    visibility: hidden;
    .modal_content {
      background-color: #f8f9fa;
      width: 37.5rem;
      height: 100%;
      position: absolute;
      right: 0px;
      transform: translateX(40rem);
      transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
      overflow-y: scroll;
      .content_inputs {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        padding-bottom: 25px;

        .select_icon {
          background-color: #f1f2f4;
          border-radius: 4px;
          padding: 2.6rem;
          height: max-content;
          margin-right: 15px;
          .icon {
            font-weight: 500;
            cursor: pointer;
            border: 1px dashed #ccc;
            color: #6d7f92;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 8.75rem;
            height: 6.5rem;
            text-align: center;
            font-size: 0.75rem;

            .icon_selected {
              font-size: 0.825rem;
              margin-top: 0.5rem;
              font-weight: 600;
              //  color: #18A0FB;
              font-family: "Avenir Next";
              text-transform: capitalize;
              display: contents;
              i {
                font-size: 2rem;
                margin-bottom: 0.6rem;
              }
            }
          }

          &.webtool {
            padding: 0.6rem;
          }
        }

        .inputs {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          padding: 0px 3rem 2px 3rem;

          ul {
            width: 100%;
            li {
              margin-bottom: 1rem;
              label {
                margin: 0 0 0.8rem 0.5rem;
                color: #3c4e64;
                display: block;
                font-size: 0.875rem;
                font-weight: normal;
                line-height: 1.8;
              }
              .input {
                height: 2.75rem;
                border: 1px solid #eaeaea;
                box-shadow: none;
                border-radius: 5px;
                padding: 0 1rem;
                margin: 0;
                font-size: 0.9rem;
              }
            }
          }

          .wrapperClassName {
            border: 1px solid #eaeaea;
          }
          .editorClassName {
            height: 162px;
            background-color: #fff;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            padding: 0 1rem 1rem 1rem;
          }

          .toolbarClassName {
            margin-bottom: 0rem;
            visibility: hidden !important;
            border-top: none;
            &.active {
              visibility: visible !important;
            }
          }

          .select {
            width: 100%;
            div {
              height: 2.9rem;
              display: flex;
              align-items: center;
              span {
                input {
                  border: 0;
                  padding: 0;
                }
              }
            }
          }

          .save_butt {
            margin-right: 10px;
            height: 2.5rem;
            width: 8rem;
            background-color: #18a0fb;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 0.3rem;
            color: white;
            cursor: pointer;

            &.disable {
              background-color: #18a0fb5c;
              cursor: not-allowed;
            }
          }

          .cancel_butt {
            height: 2.5rem;
            width: 7rem;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 0.3rem;
            border: 1px solid rgba(109, 127, 146, 0.5);
            color: #6d7f92;
            cursor: pointer;
          }

          .mini_tabs {
            span {
              font-weight: 300;
              padding: 0.25rem 0.5rem 0 0.5rem;
              background-color: #f8f9fa;
              cursor: pointer;
              border-top-left-radius: 4px;
              border-top-right-radius: 4px;
              &.active {
                border: 1px solid #eaeaea;
                font-weight: 400;
                color: #373c54;
                border-bottom: none;
                padding-bottom: 0.16rem;
                background-color: #fff;
                position: relative;

                &:first-child {
                  & ~ input {
                    border-top-left-radius: 0px;
                  }
                }
              }
            }
          }

          .languages_switch {
            padding-right: 0.5rem;
            padding-left: 0.5rem;
            margin-bottom: 2rem;
            hr {
              width: auto !important;
              margin: 0px !important;
            }
            span {
              font-weight: 300;
              font-size: 0.9rem;
            }

            .language_switch {
              display: flex;
              justify-content: space-between;
              margin-bottom: 0.6rem;
              margin-top: 0.6rem;
            }
          }
        }
      }
    }
    &.active {
      .modal_content {
        transform: translateX(0);
      }
      visibility: visible;
    }

    .icons_item {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      height: 5.5rem;
      width: 6.5rem;
      margin-right: 25px;
      min-height: inherit;
      font-size: 0.75rem;
      font-weight: 500;
      text-align: center;
      padding: 0 0.5rem;
      cursor: pointer;
      box-shadow: 0px 0px 4px rgb(41 57 77 / 10%);
      margin-bottom: 1rem;
      border-radius: 5px;
      overflow: hidden;
      transition: all 0.1s ease-in-out;
      &:hover {
        color: #18a0fb;
        transform: scale(1.1);
        background-color: #f3faff;
        border: 1px solid #18a0fb;
      }
    }
  }

  .add_modal_icons {
    position: fixed;
    inset: 0px;
    z-index: 1000;
    background-color: rgba(41, 57, 77, 0.4);
    visibility: hidden;

    .modal_icons_content {
      background-color: #f8f9fa;
      width: 37.5rem;
      height: 100%;
      position: absolute;
      right: 0px;
      transform: translateX(40rem);
      transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
      overflow-y: scroll;
      li {
        i {
          font-size: 1.75rem;
        }
      }

      .filter_icons_container {
        width: 100%;
        padding-right: 2.4rem;
        padding-left: 2.6rem;
        .filter_icons {
          display: flex;
          align-items: center;
          background-color: #fff;
          padding: 0 0 0 1.25rem;
          height: 2rem;
          border-radius: 2rem;
          border: 1px solid #f1f2f4;

          input {
            margin: 0;
            width: 100%;
            height: 100%;
            border: none;
            box-shadow: none;
            font-size: 0.75rem;
            margin-left: 4px;
            border-radius: 2rem;
          }
        }
      }
    }

    &.active {
      .modal_icons_content {
        transform: translateX(0);
      }
      visibility: visible;
    }
  }
}

.category_item {
  display: flex;
  padding: 14px 5px 14px 10px;
  background: #ffffff;
  border: 1px solid hsl(220, 12%, 95%);
  border-radius: 5px;
  margin: 0px 10px;

  &_icone {
    width: 52px;
    height: 52px;
    display: flex;
    border-radius: 50%;
    margin-right: 17px;
    align-items: center;
    justify-content: center;
    background: rgba(254, 179, 64, 0.2);
  }
  &_infos {
    display: flex;
    align-items: center;
    font-family: "Roboto";
    font-style: normal;
    padding: 5px 0px;
    width: 10.8rem;

    .category_item_title {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #29394d;
      margin-bottom: 7px;
    }
    .category_item_count {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #6d7f92;
    }
  }

  .category_actions {
    // padding: 13px 0px;
    opacity: 0;
    transition: opacity 1s;
    margin-bottom: -20px;
    margin-top: -8px;
    .category_action {
      position: relative;
      height: 35px;
      width: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: #ffffff;
      border: 0.5px solid #c7e8fe;
      box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
      svg {
        width: 15px !important;
        height: 15px !important;
      }
      &_danger {
        border: 0.5px solid #feb5bb;
        svg {
          path {
            fill: red;
          }
        }
      }
    }
    & > :first-child {
      z-index: 6;
      svg {
        z-index: 7;
      }
    }
    & > :nth-child(2) {
      top: -5px;
      z-index: 5;
      svg {
        z-index: 6;
      }
    }
  }
  &:hover {
    .category_actions {
      opacity: 1;
    }
  }
}

.gradiant {
  background-color: rgb(238, 238, 238);
  background-image: linear-gradient(
    90deg,
    rgb(238, 238, 238),
    rgb(245, 245, 245),
    rgb(238, 238, 238)
  );
  background-repeat: no-repeat;
  animation: 1.2s ease-in-out 0s infinite normal none running;
  animation-name: loading;
}

.card_tool_fetching {
  height: 192px;
  margin: 5px 0px;
  width: 305px;
}

.card_fetching {
  height: 80px;
  margin: 5px 10px;
  width: 290px;
}

.filter {
  margin-top: 1rem;
  .search_frame {
    .search {
      display: flex;
      margin-right: 14px;
      align-items: center;
      background-color: #fff;
      padding: 0 0 0 1.25rem;
      height: 2rem;
      border-radius: 2rem;
      border: 1px solid $new-light-gray;
      margin-bottom: 1rem;
      i {
        font-size: 1rem;
        color: $new-gray;
      }
      input {
        margin: 0;
        height: 100%;
        border: none;
        box-shadow: none;
        font-size: 0.75rem;
        margin-left: 1rem;
        border-radius: 2rem;
        width: 99%;
        &:focus {
          border: none;
          box-shadow: none;
        }
      }
    }
    .lng_item {
      height: 32px;
      width: 32px;
      margin: 0px 4px;
      border-radius: 50%;
      background: #f1f2f4;
      border: 1px solid #d8dde2;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      text-transform: uppercase;
      color: #6d7f92;
      margin-bottom: 1rem;
      &.activated {
        color: #f1f2f4;
        background: #18a0fb;
      }
    }
  }
}

.tag_item {
  padding: 9px 18px;
  margin: 0px 4px 10px;
  height: 32px;
  background: #f1f2f4;
  border-radius: 30px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  text-transform: uppercase;
  color: #6d7f92;
  cursor: pointer;
  &.activated {
    background-color: #6d7f92;
    color: #fff;
    font-weight: 600;
  }
}
